<template>
  <div>
    {{ spent }}
  </div>
</template>

<script>
  import useConvertDuration from '@/use/useConvertDuration'

  export default {
    name: "cellSpent",
    props: ["rowIndex", "prop", "data"],

    computed: {
      spent () {
        return useConvertDuration(this.data[this.rowIndex].totalTimeSpent)
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>